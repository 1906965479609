$right-edit-width: 400px;
$form-button-height: 28px;
$form-control-height: 44px;
$right-edit-block-padding: 2rem;
$right-edit-inline-padding: 2rem;

.page-content {
  font-family: $font-family-primary;
  .container-fluid {
    padding: 0;
  }
  .main-container {
    padding: 2rem 2.1875rem 0 1.3125rem;
    height: calc(100vh - #{$header-height});
    display: flex;
    flex-direction: column;
    gap: 9px;

    .product-item {
      border-bottom: solid 1px $gray-outline;
    }

    .page-header {
      display: flex;
      justify-content: space-between;
    }

    .recharts-legend-wrapper {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.4rem;
        .custom-legend-item {
          display: flex;
          gap: 9px;
          font-size: 12px;
          letter-spacing: 0.35px;

          .legend-surface {
            width: 18px;
            height: 18px;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .item-color-container {
    width: 2.5rem;
    .item-color {
      height: 2rem;
      width: 2rem;
      border-radius: 1rem;
    }
  }

  .reporting-container {
    padding: 1.1875rem 3.0625rem 0;
  }

  .custom-range-input {
    display: flex;
    gap: 1rem;
  }

  .form-header,
  .header-container {
    .action-button {
      width: fit-content;
      flex: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .image-container {
    min-width: 325px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #f8f8f8;
    background-color: $white;
    padding: 1rem;

    img {
      height: 291px;
      aspect-ratio: 1;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .link {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.46px;
    color: $purple-94;
    cursor: pointer;
  }

  .right-edit {
    width: $right-edit-width;
    height: calc(100vh - #{$header-height});
    padding: $right-edit-block-padding $right-edit-inline-padding;

    .title {
      line-height: $form-button-height;
    }
  }

  .change-rate {
    @include bold-text;
    font-size: 12px;
    letter-spacing: 0.35px;
    &.color-green {
      color: $green;
    }
    &.color-red {
      color: $red;
    }
  }

  .header-container {
    display: flex;
    .left-container {
      display: flex;
      flex-direction: column;
      color: $gray-34;
      .title {
        font-size: 16px;
        @include bold-text;
        letter-spacing: 0.46px;
      }
      .edit-link {
        color: $purple-94;
        cursor: pointer;
      }
      .sub-title {
        font-size: 12px;
        @include normal-text;
        letter-spacing: 0.35px;
      }
    }
  }

  .progress-bar {
    height: 10px;
    padding: 0;
    margin: 15px 0 35px 0;
    background: $gray-outline;
    display: flex;

    > span {
      display: inline-block;
      height: 100%;
      background: $purple-94;
    }
  }

  .icon-btn {
    width: fit-content;
    background: none;
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
    :hover,
    &:active {
      background: none;
    }
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray-outline;
    padding: 0.3rem;
    border-radius: 4px;
  }

  .check-lists {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .list-item {
      display: flex;
      color: $purple-7b;
      font-size: 13px;
      letter-spacing: 0.37px;
      align-items: center;
      gap: 1.5rem;
      .check-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 22px;
        width: 22px;
        padding: 0;
        color: $gray-outline;
        border: 1px solid $gray-outline;

        &.active-check-circle {
          color: $purple-94;
          border: 1px solid $purple-94;
        }

        svg {
          height: 14px;
          width: 14px;
        }
      }
    }
  }
}

.mime-form {
  .row,
  .col {
    margin: 0;
    padding: 0;
  }
  .main-container {
    padding: 2rem 2.1875rem 0 1.3125rem;
  }

  .title {
    font-size: 16px;
    @include bold-text;
    letter-spacing: 0.46px;
    color: $gray-34;
  }

  .form-control.btn-small {
    width: fit-content;
    height: $form-button-height;
    padding: 0 2rem;
    background: $purple-94;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.37px;
    color: white;

    &.with-input {
      margin: calc((#{$form-control-height} - #{$form-button-height}) / 2) 0 0
        10px;
    }
  }

  .form-control.copy-button {
    width: 94px;
    height: $form-button-height;
    padding: 0;
    background: $purple-7b;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.37px;
    color: white;
  }

  .btn-text-primary {
    color: white !important;
  }

  .btn-lg {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.69px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    color: white !important;
  }

  .btn-gray,
  .btn-gray:active {
    background: $gray-placeholder;
    border-color: $gray-placeholder;

    &:hover {
      background: $gray-500;
    }
  }

  .form-label {
    padding: 0;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.46px;
    color: $gray-5d;

    &.hidden {
      visibility: hidden;
    }
  }

  textarea.form-control {
    height: 100px;
  }

  .form-control, .form-button, select {
    height: $form-control-height;
    border: solid 1px $gray-e2;
    border-radius: 8px;
    font-size: 13px;
    @include bold-text;
    letter-spacing: 0.37px;
    color: $gray-5d;

    option {
      color: $gray-5d;
    }

    &::placeholder,
    &.empty {
      color: $gray-placeholder;
    }

    &.btn {
      color: $gray-placeholder;

      &.active {
        border: solid 1px $purple-94;
        color: $purple-94;
      }
    }

    &.form-color-input {
      padding: 4px;
      width: 52px;
    }
  }

  input[type="color" i]::-webkit-color-swatch {
    border-radius: 8px;
    border: none;
  }

  .upload-iamge-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    &::after {
      content: attr(data-content);
      display: inline-block;
      width: fit-content;
      width: -moz-fit-content;
      background-color: $purple-94;
      height: 44px;
      padding: 13px 24px;
      border-radius: 8px;
      color: $white;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.37px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
    }
    cursor: pointer;
    .upload-image {
      display: none;
    }
  }

  .pl-10 {
    padding-left: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .icon-btn {
    width: fit-content;
    background: none;
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
    :hover {
      background: none;
    }
  }
}
