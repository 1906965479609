$product-item-image-width: 60px;
$product-item-button-width: 94px;
$product-item-margin: 10px;

.page-content {
  .product-list {
    >.col {
      padding: 0;
    }

    .search-bar {
      width: 226px;
      padding: 0;
    }
  }

  .product-item {
    .product-item-image {
      width: $product-item-image-width;
      padding: 10px 0;

      img {
        width: $product-item-image-width;
        height: $product-item-image-width;
      }
    }

    .product-item-content {
      padding-left: $product-item-margin;
      // width: calc(100% - $product-item-image-width - $product-item-button-width - $product-item-margin);
      font-size: 13px;
      @include bold-text;
      letter-spacing: 0.37px;

      .title {
        color: $gray-5d;
      }

      color: $gray-5d;

      .unit-value {
        text-align: right;
      }

      .sub-title {
        color: black;
      }
    }

    .product-item-warning {
      width: 120px;

      .warning-label {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 12px;
        font-weight: bold;
        color: $light-red;
      }
    }

    .product-item-button {
      width: $product-item-button-width;
      padding-left: 0;
      padding-right: 0;

      .btn {
        width: $product-item-button-width;
        height: 28px;
        border-radius: 8px;
        background: $purple-7b;
        font-size: 13px;
        letter-spacing: 0.37px;
        color: white;
        padding: 0;
      }
    }
  }

  .bundle-products-page {
    .fit-content {
      width: 0;
      padding: 0;
    }

    .header {
      display: flex;
      align-items: center;
      gap: 1.25rem;

      .bundle-image {
        width: 6.75rem;
        aspect-ratio: 1;
      }
    }
  }

  .list-title {
    .title {
      display: flex;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.46px;
      color: $gray-34;
      padding-left: 0;
    }
  }
}

.mime-form {
  .accuracy-container {
    background-color: #fbf9ff;
    padding: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;

    .accuracy-title {
      color: $purple-mime;
      padding-bottom: 1rem;
      font-size: 12px;
      font-weight: bold;
    }

    .check-lists .list-item {

      color: $gray-34;
      gap: 1rem;

      .check-circle {
        color: $gray-placeholder;
        border: 1px solid $gray-placeholder;
      }
    }
  }

  .pricing-container {
    .title {
      margin: 40px 0 0;
      padding: 0;
      font-size: 16px;
    }

    .Price-Row {
      .Amount-Col {}

      .Currency-Col {
        margin-left: 10px;
      }

      .Button-Col {
        display: flex;
        width: auto;
        align-items: end;
        margin-left: 10px;
        padding: 0;

        .icon-btn {
          height: 44px;
          padding: 10px 0;

          .feather-plus-circle {
            color: $purple-mime;
          }
        }
      }
    }
  }

  .link-view-previous{
    color: $purple-94;
    padding: 0;
    margin-top: 12px;
  }
}