//
// _helper.scss
//

$font-size-mixing: 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 36, 48;

@each $font-size-mixing in $font-size-mixing {
  .fs-#{$font-size-mixing} {
	font-size: #{$font-size-mixing}px !important;
  }
}

// border-color
.border-dark {
	border-color: var(--#{$prefix}dark)!important;
}
.border-light {
	border-color: var(--#{$prefix}light)!important;
}

// Border Style
$border-style-mixing: double, dashed, groove, outset, ridge, dotted, inset;
@each $border-style-mixing in $border-style-mixing {
	.border-#{$border-style-mixing} {
		border-style: #{$border-style-mixing} !important;
	}
	.border-top-#{$border-style-mixing} {
		border-top-style: #{$border-style-mixing} !important;
	}
	.border-bottom-#{$border-style-mixing} {
		border-bottom-style: #{$border-style-mixing} !important;
	}
	.border-end-#{$border-style-mixing} {
		border-right-style: #{$border-style-mixing} !important;
	}
	.border-start-#{$border-style-mixing} {
		border-left-style: #{$border-style-mixing} !important;
	}
	
	// List Group Flush Style
	.list-group-flush {
		&.border-#{$border-style-mixing} { 
			border: none !important;
			.list-group-item {
				border-style: #{$border-style-mixing} !important;
			}
		}
	}
}

//link
[data-layout-mode="dark"]{
	.link-dark, .link-light{
		color: var(--#{$prefix}dark) !important;
		&:focus, &:hover{
			color: rgba(var(--#{$prefix}dark-rgb), 0.75) !important;
		}
	}
}


// Font Family

.ff-base {
	font-family: $font-family-base;
}

.ff-secondary {
	font-family: $font-family-secondary;
}

// Font weight help class

.fw-medium {
	font-weight: $font-weight-medium;
}

// Flex

.flex-1 {
	flex: 1;
}

// Social

.social-list-item {
	height: 2rem;
	width: 2rem;
	line-height: calc(2rem - 4px);
	display: block;
	border: 2px solid $gray-500;
	border-radius: 50%;
	color: $gray-500;
	text-align: center;
	transition: all 0.4s;

	&:hover {
		color: $gray-600;
		background-color: $gray-200;
	}
}


// Backgroun pattern
.bg-pattern {
	background: url("../../images/modal-bg.png") $modal-content-bg;
}

// Fixed minimum width
.w-xs {
	min-width: 80px;
}

.w-sm {
	min-width: 95px;
}

.w-md {
	min-width: 110px;
}

.w-lg {
	min-width: 140px;
}

.w-xl {
	min-width: 160px;
}

// Icons Sizes
.icon-xs {
	height: 16px;
	width: 16px;
}

.icon-sm {
	height: 18px;
	width: 18px;
}

.icon-md {
	height: 22px;
	width: 22px;
}

.icon-lg {
	height: 24px;
	width: 24px;
}

.icon-xl {
	height: 28px;
	width: 28px;
}

.icon-xxl {
	height: 32px;
	width: 32px;
}

// Dual Icons
.icon-dual {
	color: $gray-500;
	fill: rgba($gray-500,0.16);
}

@each $color,
$value in $theme-colors {
	.icon-dual-#{$color} {
		color: $value;
		fill: rgba($value, 0.16);
	}
}

// Search 
.search-box{
	position: relative;
	.form-control{
		padding-left: 40px;
	}
	.search-icon{
		font-size: 14px;
		position: absolute;
		left: 13px;
		top: 0;
		height: 100%;
		display: flex;
		align-items: center;
		color: $text-muted;
	}
}


// overlay

.bg-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	right: 0;
	bottom: 0;
	left: 0;
	top: 0;
	background-color: $black;
}

// Btn Customizer

.customizer-setting {
	position: fixed;
	bottom: 40px;
	right: 20px;
	z-index: 1000;
}

// 
code {
	user-select: all;
}

.layout-rightside {
	width: 280px;
	margin-right: -#{$grid-gutter-width};
	margin-top: calc(1px - #{$grid-gutter-width} * 1.1);
	height: calc(100% + #{$grid-gutter-width} * 1.1);
}

.layout-rightside-col {

	@media (max-width: 1699.98px) { 
		display: none;
		position: fixed !important;
		height: 100vh;
		right: 0px;
		top: 0px;
		bottom: 0px;
		z-index: 1004;
		.overlay{
			position: fixed;
			top: 0;
			right: 0px;
			bottom: 0px;
			left: 0px;
			background-color: rgba($dark, 0.2);
		   
		}
		.layout-rightside {
			margin-top: 0px;
			height: 100%;
			margin-left: auto;
		}
		.card-body {
			overflow-y: auto;
			padding-bottom: 1rem !important;
		}
	}
}

[data-layout="horizontal"], [data-layout-style=detached]{
	@media (min-width: 1700px) {
		.layout-rightside{
			margin-top: calc(28px - #{$grid-gutter-width});
			margin-right: 0;
			height: calc(100% - calc(#{$grid-gutter-width} / 5));
		}
	}
}

// object fit cover
.object-cover{
	object-fit: cover;
}

// text-truncate-two-lines
.text-truncate-two-lines{
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	white-space: normal;
}

// Favourite button 
.favourite-btn{
	border-color: transparent;
	.ri-star-fill{
		color: $text-muted;
	}
	&.active{
		border-color: transparent;
		.ri-star-fill{
			color: $warning;
			
			&:before{
				content: "\f186";
			}
		}
	}
}
// card
.card-logo-light{
	display: var(--#{$prefix}card-logo-light);
}

.card-logo-dark{
	display: var(--#{$prefix}card-logo-dark);
}

[data-layout-mode="dark"]{
	.btn-close{
		filter: invert(1) grayscale(100%) brightness(200%);
	}
}


#back-to-top {
	position: fixed;
	bottom: 100px;
	right: 28px;
	transition: all 0.5s ease;
	display: none;
	z-index: 1000;
	&:hover {
		animation: fade-up 1.5s infinite linear;
	}
}

@keyframes fade-up {
  0% {
	transform: translateY(0);
	opacity: 1;
  }

  75% {
	transform: translateY(-20px);
	opacity: 0;
  }
}

.cursor-pointer {
	cursor: pointer;
}