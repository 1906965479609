//
// _authentication.scss
//

.auth-page-wrapper {
	.auth-page-content {
		margin: calc(50vh - 16.5rem) 0 5rem;
	}
	.footer {
		left: 0;
		background-color: transparent;
		color: var(--#{$prefix}body-color);
	}
}

.auth-one-bg-position {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 380px;
	
	@media (max-width: 575.98px) {
		height: 280px;
	}
}

.auth-one-bg {
	height: 100%;
	.bg-overlay {
		height: 50vh;
		background-color: $purple-muted;
	}
}

.shape {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 1;
	pointer-events: none;
	>svg {
		width: 100%;
		height: auto;
		fill: var(--#{$prefix}body-bg);
	}
}

// auth-pass-inputgroup
.auth-pass-inputgroup {
	input[type="text"] + .btn .ri-eye-close-line{
		&:before {
			content: "\ecb5";
		}
	}
}

.particles-js-canvas-el {
	position: relative;
}

.auth-form-control {
	border-radius: 8px;
}

.auth-button {
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0.69px;
	color: $white;
	border-radius: 8px;
}

// ---------------------------  Login Page Start  ---------------------------
.auth-card {
	margin-bottom: 2.5rem;
	border-radius: 28px;
}

.auth-card-title {
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0.58px;
	color: $gray-34;
}

.auth-card-subtitle {
	font-size: 14px;
	letter-spacing: 0.4px;
	color: $gray-34;
}

.auth-card-label {
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.46px;
	color: $gray-5d;
}

.auth-btn-link {
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.46px;
	color: $purple-mime;
}

.auth-request {
	margin-top: 2.5rem;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.46px;
	color: $purple-mime;
}
// ---------------------------  Login Page End  ---------------------------

// signin card title
.auth-other-title {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		left: 0;
		right: 0;
		border-top: 1px dashed var(--#{$prefix}border-color);
		top: 10px;
	}
	.title {
		display: inline-block;
		position: relative;
		z-index: 9;
		background-color: var(--#{$prefix}card-bg-custom);
		padding: 2px 16px;
	}
}

// passowrd validations 
#password-contain {
	display: none;

	p {
		padding-left: 13px;
		&.valid {
			color: $success;

			&::before {
				position: relative;
				left: -8px;
				content: "✔";
			}
		}
		&.invalid {
			color: $danger;

			&::before {
				position: relative;
				left: -8px;
				content: "✖";
			}
		}
	}
}