//
// _topbar.scss
//

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: $header-bg;
  border-bottom: solid $border-width $gray-outline;
  transition: all 0.1s ease-out;

  &.topbar-shadow {
    box-shadow: $box-shadow;
  }

  @media (min-width: 768px) {
    left: $vertical-menu-width;
  }
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  height: calc(#{$header-height} - #{$border-width});
  margin: 0 auto;
  padding: 0 2.5rem 0 1.875rem;

  .custom-range-input {
    display: flex;
    gap: 6px;
    .start-time-pickr, .end-time-pickr {
      padding: 0;
      .form-control {
        font-weight: bold;
      }
    }
    .filter-button {
      padding: 0;
      width: fit-content;
    }
  }

  @media (max-width: 767.98px) {
    padding: 0 calc(1.875 / 2) 0 calc(1.875 / 2);
  }

  &.dashboard-header {
    height: calc(#{$dashboard-header-height} - #{$border-width});
  }

  .topbar-title {
    font-family: $font-family-primary;
    color: $gray-34;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;

    &.dashboard-title {
      letter-spacing: 0.46px;
      font-size: 16px;
    }
  }

  .topbar-head-dropdown {
    .dropdown-menu.show {
      top: 13px !important;
    }
  }

  .btn-topbar {
    height: 42px;
    width: 42px;

    @media (max-width: 360px) {
      height: 36px;
      width: 36px;
    }
  }

  .user-name-text {
    color: var(--#{$prefix}gray-700);
  }
}

/* Search */

.app-search {
  padding: calc(#{$header-height - 38px} / 2) 0;

  .form-control {
    border: none;
    height: 31px;
    padding-left: 40px;
    padding-right: 30px;
    background-color: $topbar-search-bg;
    box-shadow: none;
  }

  span.search-widget-icon {
    position: absolute;
    z-index: 10;
    font-size: 18px;
    line-height: 31px;
    left: 13px;
    top: 0;
    color: $gray-600;
  }

  .search-widget-icon-close {
    right: 7px;
    left: auto !important;
  }

  @media (max-width: 1023.99px) {
    padding-left: calc(#{$grid-gutter-width} / 2);
  }
}

.product-searchbar {
  padding-inline: 30px;
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;

    a {
      color: $dropdown-color;
    }
  }
}

@media (max-width: 767.98px) {
  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.header-item {
  height: $header-height;
  display: flex;
  align-items: center;
}

.header-profile-user {
  height: 32px;
  width: 32px;
}

.topbar-badge-sm {
  right: 0;
  top: 7px !important;
}

.topbar-badge {
  right: -9px;
  top: 4px !important;
}

.topbar-user {
  @media (min-width: 768px) {
    background-color: $topbar-user-bg;
  }

  .dropdown-menu {
    top: 6px !important;
  }
}

.notification-item {
  padding: 0.75rem 1rem;
  white-space: inherit;
  position: relative;

  .form-check-input {
    position: relative;
    z-index: 2;
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  border: 1px solid transparent;
  color: var(--#{$prefix}dropdown-link-color);

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: var(--#{$prefix}dropdown-link-hover-bg);
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle="fullscreen"] {
    .bx-fullscreen::before {
      content: "\eacb";
    }
  }
}

// Dark mode enable
[data-layout-mode="dark"] {
  .light-dark-mode {
    .bx-moon::before {
      content: "\ec34";
    }
  }
}

[data-topbar="dark"] {
  #page-topbar {
    background-color: $header-bg-dark;
    border-color: $header-bg-dark;
  }

  .navbar-header {
    .btn-topbar {
      color: $header-item-color-dark;

      &:hover,
      &:focus {
        background-color: rgba($white, 0.07);
        color: $white;
      }
    }
  }

  .topbar-user {
    @media (min-width: 767.99px) {
      background-color: $topbar-user-bg-dark;
    }

    .user-name-text {
      color: rgba($white, 0.85) !important;
    }

    .user-name-sub-text {
      color: $header-item-color-dark !important;
    }
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: inline-block;
  }

  .app-search {
    .form-control {
      background-color: rgba($white, 0.05);
      color: $white;
    }

    span.search-widget-icon,
    input.form-control::-webkit-input-placeholder {
      color: rgba($white, 0.5);
    }
  }

  .hamburger-icon {
    span {
      background-color: $gray-300;
    }
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767.98px) {
  #search-dropdown-reponsive {
    top: 54px !important;
  }
}

.topnav-hamburger {
  @media (min-width: 768px) {
    display: none;
  }
}

[data-layout="vertical"] {
  &[data-layout-style="detached"] {
    @media (min-width: 1024.1px) {
      #page-topbar {
        left: 0 !important;
        box-shadow: $box-shadow;
      }

      .horizontal-logo {
        display: inline-block;
        padding-left: 0;
      }

      .topnav-hamburger {
        visibility: hidden;
      }

      .layout-width {
        max-width: 95%;
        margin: 0 auto;
      }
    }

    &[data-topbar="dark"] {
      .horizontal-logo {
        .logo-dark {
          display: none;
        }

        .logo-light {
          display: block;
        }
      }
    }
  }
}

[data-layout="vertical"] {
  &[data-sidebar-size="sm"],
  &[data-sidebar-size="sm-hover"] {
    #page-topbar {
      @media (min-width: 768px) {
        left: $vertical-menu-width-sm;
      }
    }
  }

  &[data-sidebar-size="md"] {
    #page-topbar {
      @media (min-width: 768px) {
        left: $vertical-menu-width-md;
      }
    }
  }
}

[data-layout="twocolumn"] {
  #page-topbar {
    @media (min-width: 768px) {
      left: calc(#{$twocolumn-menu-iconview-width});
    }
  }

  .horizontal-logo {
    display: none;
  }
}
