.page-content {
  .conversion-container {
    padding: 1.1875rem 3.125rem 0 3.125rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .header {
      display: flex;
      justify-content: space-between;
    }

    .bar-chart {
      padding: 31px 8px;

      &.h-400 {
        height: 400px;
      }

      .recharts-legend-wrapper {
        display: flex;
        justify-content: center;
        // background-color: $gray-300;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          gap: 1.4rem;
          .custom-legend-item {
            display: flex;
            gap: 9px;
            font-size: 12px;
            letter-spacing: 0.35px;

            .legend-surface {
              width: 18px;
              height: 18px;
              border-radius: 4px;
            }
          }
        }
      }
    }
    .product-list {
      padding: 0;
      tr {
        font-weight: bold;
        border-bottom-color: $gray-outline;
      }
      .rounded-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 79px;
        border-bottom: none;
        > div:first-child {
          height: 32px;
          width: 32px;
          border-radius: 32px;
          background: $purple-7b;
        }
      }
    }
  }
}
