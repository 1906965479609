//
// scrollspy.scss
//

// Scrollspy
.scrollspy-example {
	position: relative;
	height: 200px;
	margin-top: .5rem;
	overflow: auto;
}

.scrollspy-example-2 {
	position: relative;
	height: 370px;
	overflow: auto;
}