.page-content {
  .meta-elements-container,
  .branding-container,
  .questions-container,
  .legal-container,
  .about-me-container,
  .my-team-container,
  .email-password-container {
    padding: 1.5rem 1.875rem 0 2.4375rem;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-lg {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        @include bold-text;
        letter-spacing: 0.69px;
        height: 44px;
        color: white !important;
        padding: 0.7625rem 4.5rem;
      }
    }
    &.p-0 {
      padding: 0 !important;
    }
  }
  .branding-container,
  .legal-container,
  .about-me-container,
  .my-team-container,
  .email-password-container {
    .mime-form {
      .header {
        margin-bottom: 1.9rem;
      }
    }
  }

  .meta-right-section {
    .image-container {
      img {
        height: 158px;
        min-height: 158px;
        aspect-ratio: unset;
        object-fit: cover;
        min-width: 100%;
      }
    }

    .founder-card {
      margin-top: 1rem;
      .foundation-finder-name {
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0.46px;
        color: #0b46d8;
      }
      .founder-description {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 0.32px;
        color: $gray-34;
      }
    }
  }

  .questions-container {
    .questions-list {
      padding: 20px 20px 26px;
      border-radius: 8px;
      background-color: rgba(244, 244, 244, 0.5);
      margin-top: 1.75rem;
    }
    .pr-20 {
      padding-right: 20px;
    }
    .input-container {
      display: flex;
      justify-content: space-between;
    }
    .question-input-section {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      color: $purple-mime;

      .icon-btn {
        .feather-plus-circle {
          color: $purple-mime;
          margin-bottom: 10px;
        }
      }

      .left-content {
        display: flex;
        flex-direction: column;
        width: calc(100% - 3rem);
      }
    }
    .question-percent {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0;
      > div {
        display: flex;
        justify-content: center;
        width: 60px;
        height: 24px;
        padding: 3px 16px 4px 19px;
        border-radius: 8px;
        margin-bottom: 10px;
        background-color: $purple-7b;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.46px;
      }
      .active-value {
        background-color: #89b171;
      }

      .p-0 {
        padding: 0;
      }
    }
  }

  .my-team-container {
    .user-row {
      div {
        padding: 0;
      }

      .role-col {
        width: auto;
        margin: 0 20px 0 15px;

        select {
          width: 125px;
        }
      }

      .button-col {
        display: flex;
        width: auto;
        align-items: end;

        .btn-add {
          height: 44px;
          border-radius: 8px;
          width: 100px;
        }

        .icon-btn {
          height: 44px;
          padding: 10px 0;
        }
      }
    }
  }
}
