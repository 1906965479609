//
// _form-check.scss
//

.form-check {
	position: relative;
	text-align: left
		/*rtl: right*/;

	.form-check-input {
		cursor: pointer;
	}

	label {
		cursor: pointer;
	}
}


// checkbox color

@each $color,
$value in $theme-colors {
	.form-check-#{$color} {
		.form-check-input {
			&:checked {
				background-color: $value;
				border-color: $value;
			}
		}
	}

	.form-radio-#{$color} {
		.form-check-input {
			&:checked {
				border-color: $value;
				background-color: $value;

				&:after {
					background-color: $value;
				}
			}
		}
	}
}

.form-check-label {
	cursor: pointer;
	margin-bottom: 0;
}

// checkbox input right
.form-check-right {
	padding-left: 0;
	display: inline-block;

	.form-check-input {
		float: right;
		margin-left: 0;
		margin-right: $form-check-padding-start * -1;
	}

	.form-check-label {
		display: block;
	}

	&.form-switch {
		.form-check-input {
			margin-right: $form-check-padding-start * -1.6;
		}
	}
}


// Checkbox outline

@each $color,
$value in $theme-colors {
	.form-check-outline {
		&.form-check-#{$color} {
			.form-check-input {
				&:checked[type=checkbox] {
					color: $value;
					background-color: transparent;
					border-color: $value;
				}
			}
		}
	}
}

.form-check-outline {
	.form-check-input {
		position: relative;

		&:checked[type=checkbox] {
			background-image: none;

			&::before {
				content: "\F012C";
				font-family: "Material Design Icons";
				top: -2px;
				position: absolute;
				font-weight: 700;
				font-size: 11px;
				left: 1px;
			}
		}

	}
}

// Radio

@each $color,
$value in $theme-colors {
	.form-radio-outline {
		&.form-radio-#{$color} {
			.form-check-input {
				&:checked[type=radio] {
					color: $value;
					background-color: transparent;
					border-color: $value;
				}
			}
		}
	}
}

.form-radio-outline {
	.form-check-input {
		position: relative;

		&:checked[type=radio] {
			background-image: none;

			&::before {
				content: "\F0765";
				font-family: "Material Design Icons";
				top: 0px;
				position: absolute;
				font-size: 8px;
				left: 2.2px;
			}
		}

	}
}

// Switch sizes

.form-switch-md {
	padding-left: 2.5rem;
	min-height: 22px;
	line-height: 22px;

	.form-check-input {
		width: 40px;
		height: 20px;
		left: -0.5rem;
		position: relative;
	}

	.form-check-label {
		vertical-align: middle;
	}
}

.form-switch-lg {
	padding-left: 2.75rem;
	min-height: 28px;
	line-height: 28px;

	.form-check-input {
		width: 48px;
		height: 24px;
		left: -0.75rem;
		position: relative;
	}
}

.input-group-text {
	margin-bottom: 0px;
}


@each $color,
$value in $theme-colors {
	.form-switch-#{$color} {
		.form-check-input {

			&:checked {
				background-color: $value;
				border-color: $value;
			}
		}
	}

	.form-switch-custom {
		&.form-switch-#{$color} {
			.form-check-input {
				&:checked {
					&::before {
						color: $value;
					}

				}
			}
		}
	}
}

// Custom Switch style

.form-switch-custom {
	.form-check-input {
		position: relative;
		background-image: none;

		&::before {
			content: "\F0765";
			font-family: "Material Design Icons";
			top: -9px;
			position: absolute;
			font-size: 20px;
			left: -3px;
			color: $text-muted;
			@include transition($form-switch-transition);
		}

		&:checked {
			background-image: none;
			background-color: $form-check-input-bg;
			@include transition($form-switch-transition);

			&::before {
				right: -3px;
				left: auto;
			}
		}

		&:focus {
			background-image: none;
		}
	}
}

// Switch - Right

.form-switch-right {
	display: inline-block;
	padding-right: $form-check-padding-start * .5;
	margin-bottom: 0;
	padding-left: 0 !important;

	.form-check-input {
		float: right;
		margin-left: 0;
		margin-right: $form-check-padding-start * -1;
		margin-top: .1em !important;
	}

	label {
		margin-bottom: 0;
		margin-right: 1rem;
	}
}

// card radio
.card-radio {
	padding: 0;

	.form-check-label {
		background-color: var(--#{$prefix}card-bg-custom);
		border: 1px solid var(--#{$prefix}input-check-border);
		border-radius: $border-radius;
		padding: 1rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
		position: relative;
		padding-right: 32px;

		&:hover {
			cursor: pointer;
		}
	}

	.form-check-input {
		display: none;

		&:checked+.form-check-label {
			border-color: $primary !important;

			&:before {
				content: '\eb80';
				font-family: "remixicon";
				position: absolute;
				top: 2px;
				right: 6px;
				font-size: 16px;
				color: $primary;
			}
		}
	}

	&.dark {
		.form-check-input {
			&:checked+.form-check-label {
				&:before {
					color: $white;
				}
			}
		}
	}
}

[data-layout-mode="dark"]{
	.form-switch{
		.form-check-input, .form-check-input:focus{
			background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$gray-400}'/></svg>"));
		}
	}
}
