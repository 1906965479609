.page-content {
  .returns-container {
    .sub-title {
      padding: 0;
    }

    .product-item-content {
      width: 100%;
    }

    .fit-content {
      .icon-btn {
        margin-left: auto;
        background: none;
        border: none;
        padding: 0;
      }
    }
  }

  .header-container {
    padding: 0;
    margin: 0;
  }

  .training-container {
    display: flex;
    justify-content: center;

    .training-card {
      max-width: 325px;
      margin-top: 6.8125rem;

      .list-title {
        padding: 0;
        margin: 0;
      }

      .training-details {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0;

        .history {
          display: flex;
        }

        .image-container {
          img {
            min-height: 291px;
            min-width: 100%;
          }
        }

        .selector {
          position: relative;
          display: flex;
          align-items: center;
          padding: 0;

          .form-select.left-padding {
            padding-left: 3.125rem;
          }

          .prefix {
            position: absolute;
            height: 18px;
            width: 18px;
            border-radius: 9px;
            left: 20px;
          }
        }

        .training-footer {
          color: $purple-7b;
          text-align: center;

          >span {
            text-decoration: $link-decoration;
          }
        }
      }
    }
  }

  .history-container{
    display: flex;
    gap: 20px;

    img {
      height: 165px;
    }

    & .selector{
      padding: 0;
    }
  }
}