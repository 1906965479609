//
// forms.scss
//

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
	&::placeholder {
		text-align: left /*rtl: right*/;
	}
}
