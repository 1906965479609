//   Revenue section style

.revenue-container {
  .revenue-wrapper {
    padding: 10px 6px 20px 7px;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;

    [class*="col-"] {
      padding: 0;
      margin: 0;
    }

    .card-title {
      margin-left: 14px;
      color: $black;
    }

    .revenue-details-container {
      border: 1px dashed $gray-400;
      border-left-width: 0;
      border-right-width: 0;
      .details-card {
        display: flex;
        gap: 1rem;
        justify-content: center;
        padding: 1rem 2rem;
        border-right: 1px dashed $gray-400;
        background-color: $gray-50;
        height: 100%;

        &.border-right-none {
          border-right:none ;
        }

        .amount {
          color: $black;
          letter-spacing: 0.46px;
          @include bold-text;
          font-size: 1.2rem;

          &.color-purple-light {
              color: #3e5288;
          }
        }

        .heading {
          font-size: 16px;
          color: $purple-7b;
          @include normal-text;
          font-weight: 600;
          display: flex;
          align-items: center;
        }

        > span {
          text-align: center;
        }
      }
    }

    .chart {
      display: flex;
      height: min(350px, 350px);
      font-size: 14px;
      letter-spacing: 0.35px;
      padding: 0 0toprem 1rem;

      .recharts-legend-wrapper {
        bottom: -5px !important;
      }
      .recharts-default-legend {
        display: flex;
        justify-content: center;
        gap: 6px;
        .recharts-legend-item {
          display: flex !important;
          align-items: center;

          &.legend-item-1 {
            display: none !important;
          }
          .recharts-legend-item-text {
            font-size: 0.8125rem;
            color: $purple-7b !important;
            letter-spacing: 0.35px;
            @include bold-text;
          }
        }
      }
    }
  }
}
