$common-border: solid 1px #f8f8f8;
$right-edit-width: 300px;
// $height-sales: 109px;
$height-sales: 0px;
$height-revenue: 530px;
$height-best-seller: 475px;
$height-top-complexion: 302px;
$height-top-undertone: 302px;
$height-top-color-preference: 380px;
$height-online-shopper: $height-sales + $height-revenue + 20px + $height-best-seller + 30px + 20px;

// Date picker input overrides style
.rs-picker-daterange-menu {
  left: unset !important;
  right: 24px;
  z-index: 2999 !important;
}

.rs-picker-daterange {
  width: 225px !important;
  height: 35px !important;
  margin-bottom: 0 !important;

  .rs-picker-toggle.rs-btn-lg {
    padding-top: 5.5px !important;
    padding-bottom: 5.5px !important;
  }

  .rs-picker-toggle-caret {
    right: 11.5px !important;
    top: 7px !important;
    fill: $white;
  }

  .rs-picker-toggle-value,
  .rs-picker-toggle-textbox {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.35px;
    color: $gray-34 !important;
    font-family: $font-family-primary;
  }

  .rs-picker-toggle-value::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 35px !important;
    height: 35px;
    background-color: $purple-muted;
  }
}

//for when dashboard is open taking full screen
#layout-wrapper {
  .secondary-menu {
    margin-top: #{$header-height};

    .twocolumn-iconview {
      margin-top: -#{$header-height};
    }
  }

  .primary-menu {
    margin-top: 71px;
  }

  &.dashboard-wrapper {
    .navbar-menu {
      height: 0;
      width: 0;
      padding-top: 0;
      transition: all 0s ease-in-out;

      .navbar-nav {
        height: 0;

        .simplebar-wrapper {
          width: 0;
        }
      }
    }

    .main-content {
      margin-left: 70px;
    }
  }
}

// dashboard page
.dashboard,
.common-wrapper {

  .col,
  .row,
  .span {
    padding: 0;
    margin: 0;
    font-family: $font-family-primary;
  }

  .dashboard-container {
    width: calc(100vw - 70px - 300px);
    padding: 1.875rem 2rem 1.3125rem 1.875rem;
    background-color: $body-bg-secondary;
    border-right: solid 1px $gray-outline;
    display: flex;
    flex-direction: column;
    gap: 19px;
  }

  //  Dashboard top section
  .card-container {
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    height: 100%;
    flex-wrap: nowrap;
    padding: 0 !important;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }

    .card {
      display: flex !important;
      max-width: 350px;
      min-width: 350px;
      flex-direction: row;
      padding: 20px 20px 17px;
      border-radius: 0.5rem;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
      justify-content: space-between;
      gap: 12px;

      .left-content {
        display: flex;
        flex-direction: column;
        width: 40%;

        .title {
          white-space: nowrap;
          font-size: 12px;
          color: $purple-7b;
          letter-spacing: 0.35px;
          @include bold-text;
        }

        .value {
          white-space: nowrap;
          font-size: 26px;
          letter-spacing: 0.75px;
          color: $black;
          @include normal-text;
        }
      }

      .right-content {
        height: 100%;
        width: 60%;
      }

      .chart {
        display: flex;
        align-items: center;
        height: 100%;

        .recharts-surface {
          max-width: 100%;
        }

        .recharts-responsive-container {
          max-width: 178px !important;
          height: 72px;
          min-height: 72px;
          max-height: 72px;
          transform: translateY(6px);
        }
      }
    }
  }

  // All category section style
  .toppers-container {
    display: flex;
    gap: 22px;
    padding: 0;
    width: calc(100%);

    .left-wrapper,
    .right-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;

      .recharts-tooltip-wrapper {
        background: none;
        border: none;
      }

      // Revenue section style
      .revenue-wrapper {
        padding: 20px 19px 23px;
        display: flex;
        flex-direction: column;
        gap: 0.9rem;
        height: #{$height-revenue};

        [class*="col-"] {
          padding: 0;
          margin: 0;
        }

        .revenue-duration-tabs {
          display: flex;
          justify-content: flex-end;
          gap: 6px;
          padding-right: 1rem;

          .duration {
            padding: 0.3rem 0.7rem;
            width: fit-content;
            background-color: #ebf1fe;
            color: #3f7ff2;
            border-radius: 6px;
            cursor: pointer;
            display: inline-block;
            font-size: 12px;
            @include bold-text;

            &.active-tab {
              color: #3d5087;
              background-color: #ecedf4;
            }
          }
        }

        .revenue-details-container {
          border: 1px dashed $gray-400;
          border-left-width: 0;
          border-right-width: 0;

          .details-card {
            display: flex;
            flex-direction: column;
            gap: 4px;
            justify-content: center;
            padding: 1rem 2rem;
            border-right: 1px dashed $gray-400;
            background-color: $gray-50;
            height: 100%;

            .amount {
              color: $black;
              font-size: 1rem;
              letter-spacing: 0.46px;
              @include bold-text;

              >.change-rate {
                font-size: 1rem;
              }
            }

            .heading {
              font-size: 13px;
              color: $purple-7b;
              @include normal-text;
              font-weight: 600;
            }

            >span {
              text-align: center;
            }
          }
        }

        .chart {
          display: flex;
          height: min(350px, 350px);
          font-size: 14px;
          letter-spacing: 0.35px;
          padding: 0 0toprem 1rem;

          .recharts-cartesian-axis-ticks {
            .recharts-text {
              fill: $gray-placeholder;
            }
          }

          .recharts-cartesian-grid {
            .recharts-cartesian-grid-vertical {

              &:nth-child(n),
              &:nth-child(n-1) {
                display: none;
              }
            }
          }

          .recharts-legend-wrapper {
            bottom: -5px !important;
          }

          .recharts-default-legend {
            display: flex;
            justify-content: center;
            gap: 6px;

            .recharts-legend-item {
              display: flex !important;
              align-items: center;

              .recharts-legend-item-text {
                font-size: 0.8125rem;
                color: $purple-7b !important;
                letter-spacing: 0.35px;
                @include bold-text;
              }
            }
          }
        }
      }

      // best seller and top 3rd part referrals
      .footer-container {
        display: flex;
        gap: 23px;
        width: 100%;
        height: #{$height-best-seller};

        .best-sellers,
        .top-referrals,
        .top-countries {
          height: 100%;
          width: calc(50% - 11.5px);

          .common-card {
            width: 100%;
            padding: 1.25rem 1.3125rem 0.875rem 1.375rem;
            display: flex;
            flex-direction: column;
            gap: 9px;
            height: 100%;
          }
        }
      }

      // top complexions, top undertones and top color prefrences
      .top-complexions {
        height: #{$height-top-complexion};
      }

      .top-undertones {
        height: #{$height-top-undertone};
      }

      .top-color-preferences {
        height: #{$height-top-color-preference};
      }

      .top-complexions,
      .top-undertones,
      .top-color-preferences {
        .common-card {
          padding: 20px 19px 23px;
          display: flex;
          flex-direction: column;
          gap: 9px;
          height: 100%;
        }
      }

      .top-complexions,
      .top-undertones {
        .common-card {
          overflow: auto;

          .header {
            position: sticky;
            top: -20px;
            z-index: 1;

            .card-title {
              background-color: $white;
            }
          }

          &::-webkit-scrollbar {
            width: 3px;
            height: 1px;
            background: $gray-400;
          }
        }
      }

      .top-color-preferences {
        .chart {
          display: flex;
          justify-content: center;
          height: #{$height-top-color-preference};

          .recharts-legend-wrapper {
            ul {
              list-style: none;
              padding: 0;
              margin: 0;
              display: flex;
              flex-wrap: wrap;
              gap: 1.4rem;

              .custom-legend-item {
                display: flex;
                flex: 1 0 calc(((100% - 38px) / 2) - 1.4rem);
                gap: 9px;
                font-size: 12px;
                letter-spacing: 0.35px;

                .legend-surface {
                  width: 18px;
                  height: 18px;
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }
    }

    .left-wrapper {
      width: calc(74% - 11px);
    }

    .right-wrapper {
      width: calc(26% - 11px);
    }
  }

  .last-update-status {
    margin-top: 1.8rem;
    font-size: 10px;
    color: $gray-34;
    @include normal-text;
    letter-spacing: 0.29px;
    text-align: right;
  }

  //   right side container
  .right-side-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.125rem;

    .performance-tips-container {
      padding: 0;

      .col,
      .row {
        padding: 0;
        margin: 0;
      }

      .tips {
        padding: 1rem 0;
        border-bottom: solid 1px $gray-outline;
        font-size: 14px;
        @include normal-text;
        letter-spacing: 0.4px;

        .fix-link {
          color: $dark-red;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .online-shoppers-container {
      height: #{$height-online-shopper};

      .shopper-activity {
        font-size: 12px;
        color: $purple-7b;
        @include normal-text;
        letter-spacing: 0.35px;
      }

      .item-image {
        width: 41px !important;

        img {
          min-width: 41px !important;
          height: 41px !important;
        }
      }

      .shopper-status {
        font-size: 12px;
        letter-spacing: 0.35px;

        .feather {
          height: 14px;
          width: 14px;
        }

        &.search-status {
          color: $purple-7b;
        }

        &.matching-status {
          color: #89c352;
        }
      }
    }
  }

  //   common style
  .common-card {
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
    border: $common-border;
    background-color: $common-card-bg;

    .card-title {
      font-size: 16px;
      color: $purple-7b;
      @include bold-text;
      letter-spacing: 0.46px;
    }
  }

  .item-list {
    td {
      white-space: pre-wrap;
    }

    .item {
      height: calc(44px + 37px);
      border-bottom: solid 1px $gray-outline;

      .item-image {
        width: 44px;
        padding: 0;

        img {
          min-width: 44px;
          height: 44px;
          width: 100%;
          border-radius: 9px;
          object-fit: cover;
        }
      }

      .item-color {
        height: 18px;
        width: 18px;
        border-radius: 4px;
      }

      .item-name {
        font-size: 14px;
        @include normal-text;
        letter-spacing: 0.4px;
        color: $black;
      }

      .item-value {
        font-size: 12px;
        letter-spacing: 0.35px;
        text-align: right;
        color: $purple-7b;
        @include normal-text;
      }
    }

    .item-sm {
      height: 44px;

      .item-image {
        width: 18px;
      }
    }
  }

  .card-title {
    font-size: 16px;
    color: $purple-7b;
    @include bold-text;
    letter-spacing: 0.46px;
  }

  .border-none {
    border-right-width: 0 !important;
  }

  .right-edit {
    width: $right-edit-width;
  }
}

@media (max-width:1440px) {
  .left-wrapper {
    width: calc(70% - 11px) !important;
  }

  .right-wrapper {
    width: calc(30% - 11px) !important;
  }
}