//
// toast.scss
//


// toast
.toast { 
	&:not(:last-child) {
	  margin-bottom: $toast-padding-x;
	}
}

@mixin toast-border-variant($bg) {
	.toast-body{
		color: $bg;
		border-bottom: 3px solid $bg;
	}
}

@each $color, $value in $theme-colors {
	.toast-border-#{$color} {
		@include toast-border-variant($value);
	}
}