//
// _vertical.scss
// 

.app-content {
	margin-left: $vertical-menu-width;
	overflow: hidden;

	.content {
		padding: 0 15px 10px 15px;
		margin-top: $header-height;
	}
}

.main-content {
	transition: all .1s ease-out;

	@media (min-width: 768px) {
		margin-left: $vertical-menu-width;
	}
}

.page-content {
	padding: calc(#{$header-height}) calc(#{$grid-gutter-width} * 0.5) $footer-height 0px;
	
	&.dashboard {
		padding: calc(#{$dashboard-header-height}) calc(#{$grid-gutter-width} * 0) $footer-height 0px;

	}
}

.navbar-menu {
	width: $vertical-menu-width;
	z-index: $vertical-menu-z-index;
	background: $vertical-menu-bg;
	border-right: 1px solid $vertical-menu-border;
	bottom: 0;
	margin-top: 0;
	position: fixed;
	top: 0;
	box-shadow: $vertical-menu-box-shadow;
	padding: 0 0 calc(#{$header-height} + 25px) 0;
	transition: all .1s ease-out;

	.navbar-nav {
		.nav-link {
			display: flex;
			align-items: center;
			padding: $vertical-menu-item-padding-y $vertical-menu-item-padding-x;
			color: $vertical-menu-item-color;
			font-size: $vertical-menu-item-font-size;
			font-family: $vertical-menu-item-font-family;
			
			&.private-item {
				display: flex;
				justify-content: space-between;
			}

			&.sub-item {
				text-indent: 2rem !important;
			}

			&.active {
				color: $vertical-menu-item-active-color;
				border-right: solid 5px $purple-94;
			}

			&:hover {
				color: $vertical-menu-item-hover-color;
				background: $gray-outline;
			}

			i {
				display: inline-block;
				min-width: $vertical-menu-item-icon-width;
				font-size: 18px;
				line-height: inherit;
			}

			svg {
				width: 18px;
				margin-right: 0.665rem;
				color: $vertical-menu-item-color;
			}

			.badge {
				margin-left: auto;
				margin-right: -2px;
				z-index: 1;
			}

			&:hover {
				color: $vertical-menu-item-hover-color;

				.icon-dual {
					color: $vertical-menu-item-hover-color;
					fill: rgba($primary, 0.16);
				}
			}

			&[data-bs-toggle=collapse] {
				&[aria-expanded=true] {
					border-right: solid 0 $purple-94;

					.icon-dual {
						color: $vertical-menu-item-hover-color;
						fill: rgba($primary, 0.16);
					}
				}
			}
			&.collapsed {
				&.active {
					&::after {
						transform: rotate(90deg);
					}
				}
			}
		}

		.nav-sm {
			.nav-button {
				color: $purple-mime !important;
			}

			.nav-link {
				padding: $vertical-menu-sub-item-padding-y $vertical-menu-sub-item-padding-x $vertical-menu-sub-item-padding-y $vertical-menu-item-icon-width !important;
				color: $vertical-menu-sub-item-color;
				white-space: none;
				position: relative;
				font-size: $vertical-menu-sub-item-font-size;
				font-family: $vertical-menu-sub-item-font-family;
				font-weight: bold;
				letter-spacing: 0.46px;

				&:hover {
					color: $vertical-menu-item-hover-color;
					background: $gray-outline;
				}

				&.active {
					color: $vertical-menu-item-active-color;
					border-right: solid 5px $purple-94;
				}
			}

			.nav-sm {
				.nav-link {
					color: $vertical-menu-sub-item-color;
					font-weight: 500;
					letter-spacing: 0.37px;
				}
			}
		}
	}

	.btn-vertical-sm-hover {
		color: $text-muted;
		display: none;
	}
}

.hamburger-icon {
	width: 20px;
	height: 14px;
	position: relative;
	cursor: pointer;
	display: inline-block;

	span {
		background-color: $text-muted;
		position: absolute;
		border-radius: 2px;
		transition: .3s cubic-bezier(.8, .5, .2, 1.4);
		width: 100%;
		height: 2px;
		display: block;
		left: 0px;

		&:nth-child(1) {
			top: 0;
			width: 80%;
		}

		&:nth-child(2) {
			top: 6px;
		}

		&:nth-child(3) {
			bottom: 0;
			width: 60%;
		}
	}

	.vertical-menu-btn:hover &:not(.open) {
		span {

			&:nth-child(1) {
				top: -1px;
			}

			&:nth-child(3) {
				bottom: -1px;
			}
		}
	}

	&.open {
		transform: rotate(-90deg);

		span {

			&:nth-child(1) {
				left: 1px;
				top: 5px;
				width: 20px;
				transform: rotate(90deg);
				transition-delay: 150ms;
			}

			&:nth-child(2) {
				left: 3px;
				top: 13px;
				width: 10px;
				transform: rotate(45deg);
				transition-delay: 50ms;
			}

			&:nth-child(3) {
				left: 9px;
				top: 13px;
				width: 10px;
				transform: rotate(-45deg);
				transition-delay: 100ms;
			}
		}
	}
}

.logo {
	line-height: $header-height;

	.logo-sm {
		display: none;
	}
}

.logo-light {
	display: none;
}

[data-layout="vertical"] {
	.app-menu {
		.row {
			margin: 0;

			>* {
				width: 100%;
				padding: 0;
			}
		}

		@media (max-width: 767.98px) {
			margin-left: -100%;
			padding: 10px 0 20px 0;
		}

	}

	.navbar-menu {
		.container-fluid {
			padding: 0;
		}
	}

	.horizontal-logo {
		display: none;
	}

	&[data-sidebar-size="sm-hover"] {

		.main-content {
			margin-left: $vertical-menu-width-sm;

			@media (max-width: 767.98px) {
				margin-left: 0;
			}
		}

		.logo {
			span.logo-lg {
				display: none;
			}

			span.logo-sm {
				display: inline-block;
			}
		}

		.btn-vertical-sm-hover {
			display: inline-block;
		}

		@media (min-width: 768px) {
			.topnav-hamburger {
				display: none;
			}
		}

		// Side menu
		.navbar-menu {
			width: $vertical-menu-width-sm;

			.btn-vertical-sm-hover {
				display: none;

				i {
					&.ri-record-circle-line:before {
						content: "\EB7D";
					}
				}
			}

			// Sidebar Menu
			.navbar-nav {

				.badge {
					display: none;
				}

				.menu-title {
					text-align: center;

					span {
						display: none;
					}

					i {
						display: block;
						line-height: 36px;
						font-size: 1rem;
					}
				}

				.nav-link {
					span {
						display: none;
					}

					i {
						font-size: 22px;

						&.las,
						&.lar,
						&.lab {
							font-size: 24px;
						}
					}

					svg {
						margin-right: 0px;
					}

					&:after,
					&:before {
						display: none;
					}
				}

				.menu-dropdown {
					display: none;
				}
			}

			&:hover {
				width: $vertical-menu-width !important;

				@media (min-width: 1024.99px) {
					.btn-vertical-sm-hover {
						display: inline-block;
					}
				}

				.navbar-nav {
					.menu-dropdown.show {
						display: block;
					}
				}

				.nav-link {

					i {
						font-size: 18px;

						&.las,
						&.lar,
						&.lab {
							font-size: 20px;
						}
					}

					svg {
						margin-right: 0.665rem;
					}

					span,
					&:after,
					&:before {
						display: inline-block;
					}
				}

				.logo {
					span.logo-lg {
						display: inline-block;
					}

					span.logo-sm {
						display: none;
					}
				}

				.menu-title {
					text-align: left;

					span {
						display: inline-block;
					}

					i {
						display: none;
					}
				}
			}
		}
	}

	&[data-sidebar-size="sm-hover"],
	&[data-sidebar-size="sm-hover-active"] {
		.navbar-header {
			@media (min-width: 1025px) {
				padding-left: $grid-gutter-width;
			}
		}
	}

	&[data-sidebar-size="sm-hover-active"] {
		.topnav-hamburger {
			display: none;
		}

		.btn-vertical-sm-hover {
			display: inline-block;
		}
	}

	&[data-sidebar-size="sm"] {
		@media (min-width: 768px) {
			min-height: 1400px;

			.main-content {
				margin-left: $vertical-menu-width-sm;
			}
		}

		#page-topbar {
			z-index: calc(#{$vertical-menu-z-index} + 1);
		}

		.logo {
			span.logo-lg {
				display: none;
			}

			span.logo-sm {
				display: inline-block;
			}
		}

		// Side menu
		.navbar-menu {
			position: absolute;
			width: $vertical-menu-width-sm !important;
			padding-top: $header-height;

			.simplebar-mask,
			.simplebar-content-wrapper {
				overflow: visible !important;
			}

			.simplebar-scrollbar,
			.vertical-menu-btn {
				display: none !important;
			}

			.simplebar-offset {
				bottom: 0 !important;
			}

			// Sidebar Menu
			.navbar-nav {

				.badge {
					display: none !important;
				}

				.menu-title {
					text-align: center;
					font-size: 1rem;

					span {
						display: none;
					}

					i {
						display: block;
						line-height: 36px;
					}
				}

				.nav-link {
					span {
						display: none;
					}

					i {
						font-size: 22px;
					}

					svg {
						margin-right: 0px;
					}

					&:after,
					&:before {
						display: none;
					}
				}

				.menu-dropdown {
					display: none;
					height: auto !important;
				}

				.nav-item {
					position: relative;

					&:hover {

						>a.menu-link {
							position: relative;
							width: calc(200px + #{$vertical-menu-width-sm});
							color: $white;
							background-color: $vertical-menu-bg-dark;
							transition: none;

							.icon-dual {
								color: $vertical-menu-item-hover-color-dark;
								fill: rgba($white, 0.16);
							}

							span {
								display: inline-block;
								padding-left: 25px;
							}

							&:after {
								display: block;
								transform: rotate(90deg);
								color: $white;
							}
						}

						>.menu-dropdown {
							display: block;
							left: $vertical-menu-width-sm;
							position: absolute;
							width: 200px;
							background: $vertical-menu-bg;
							height: auto !important;
							padding: 0.5rem 0;
							border-radius: 0 0 3px 3px;
							box-shadow: $vertical-menu-dropdown-box-shadow;
						}
					}
				}

				.nav-sm {
					padding: 0;

					.nav-item {
						&:hover {
							>.nav-link {
								color: $vertical-menu-item-hover-color;

								&:after {
									color: inherit;
								}
							}
						}
					}

					.nav-link {
						&:after {
							display: block !important;
							transform: rotate(0deg) !important;
						}
					}

					.menu-dropdown {
						left: 100% !important;
						top: 0;
						border-radius: 3px !important;
					}
				}
			}
		}
	}

	&[data-sidebar-size="md"] {
		@media (min-width: 768px) {
			.main-content {
				margin-left: $vertical-menu-width-md;
			}
		}

		// Side menu
		.navbar-menu {
			width: $vertical-menu-width-md !important;

			// Sidebar Menu
			.navbar-nav {
				.nav-link {
					display: block;
					text-align: center;
					padding: $vertical-menu-sub-item-padding-y $vertical-menu-sub-item-padding-x*0.35;

					i {
						display: block;
					}

					svg {
						display: block;
						margin-left: auto;
						margin-right: auto;
					}

					&:before {
						display: none !important;
					}

					&[data-bs-toggle=collapse]:after {
						position: relative;
						display: inline-block;
						right: 0;
						top: 3px;
					}

					&.menu-link {
						&[data-bs-toggle=collapse]:after {
							display: none;
						}
					}
				}

				.badge {
					display: none !important;
				}

				.nav-sm {
					padding-left: 0;
				}
			}


			.menu-title {
				text-align: center;

				span {
					text-decoration: underline;
				}
			}
		}
	}

	&[data-sidebar="dark"] {
		.navbar-menu {
			background: $vertical-menu-bg-dark;
			border-right: 1px solid $vertical-menu-border-dark;

			.hamburger-icon {
				span {
					background-color: $vertical-menu-item-color-dark;
				}
			}

			.btn-vertical-sm-hover {
				color: $vertical-menu-item-color-dark;
			}
		}

		.navbar-nav {

			.nav-link {
				color: $vertical-menu-item-color-dark;

				&.active {
					color: $vertical-menu-item-active-color;
					border-right: solid 5px $purple-94;
				}

				&[data-bs-toggle=collapse] {
					&[aria-expanded=true] {
						border-right: solid 0 $purple-94;

						.icon-dual {
							color: $vertical-menu-item-active-color-dark;
							fill: rgba($white, 0.10);
						}
					}
				}

				svg {
					color: $vertical-menu-item-color-dark;
					fill: rgba($white, 0.10);
				}

				&:hover {
					color: $vertical-menu-item-hover-color-dark;

					.icon-dual {
						color: $vertical-menu-item-hover-color-dark;
						fill: rgba($white, 0.16);
					}
				}

			}

			>.nav-item {
				.nav-link.active {
					color: $vertical-menu-item-active-color;
					border-right: solid 5px $purple-94;

					.icon-dual {
						color: $vertical-menu-item-hover-color-dark;
						fill: rgba($white, 0.16);
					}
				}
			}

			.nav-sm {
				.nav-link {
					color: $vertical-menu-item-color;

					&:hover {
						color: $vertical-menu-item-hover-color;
						background: $gray-outline;
					}

					&.active {
						color: $vertical-menu-item-active-color;
						border-right: solid 5px $purple-94;
					}
				}

				.nav-sm {
					.nav-link {
						&:hover {
							color: $vertical-menu-item-hover-color;
							background: $gray-outline;
						}

						&.active {
							color: $vertical-menu-item-active-color;
							border-right: solid 5px $purple-94;
						}
					}
				}
			}
		}

		&[data-sidebar-size="sm"] {
			// Side menu
			.navbar-menu {
				.navbar-nav {
					.nav-item {

						&:hover {
							>.menu-dropdown {
								background: $vertical-menu-bg-dark;
							}
						}
					}

					.nav-sm {
						padding: 0;

						.nav-link {
							&:after {
								display: block !important;
								transform: rotate(0deg) !important;
							}
						}

						.nav-item {
							&:hover {
								>.nav-link {
									background: $gray-outline;
								}
							}
						}

						.menu-dropdown {
							left: 100% !important;
							top: 0;
							border-radius: 3px !important;
						}
					}
				}
			}
		}

		.menu-title {
			color: $vertical-menu-title-color-dark;
		}

		.logo-dark {
			display: none;
		}

		.logo-light {
			display: inline-block;
		}
	}

	&[data-sidebar="light"] {
		.logo-dark {
			display: inline-block;
		}

		.logo-light {
			display: none;
		}
	}

	&[data-layout-style="detached"] {

		#layout-wrapper,
		.main-content {
			min-height: 100vh;
		}

		@media (min-width: 1024.1px) {
			.main-content {
				position: relative;
			}

			#layout-wrapper {
				max-width: 95%;
				margin: 0 auto;
				padding-left: $grid-gutter-width;
			}

			.navbar-header {
				padding-left: $grid-gutter-width;
			}

			.navbar-menu {
				top: calc(#{$header-height} + #{$grid-gutter-width});
				bottom: $grid-gutter-width;
				padding: 0;
				border-right: $vertical-menu-bg;
				border-radius: 5px;
				padding: 10px 0;
				z-index: 1;
			}
		}

		.footer {
			border-top: 1px dashed $border-color;
		}

		.auth-page-wrapper {
			.footer {
				border-top: none;
			}
		}

		&[data-sidebar-size="sm"] {
			@media (min-width: 768px) {

				#layout-wrapper,
				.main-content {
					min-height: 1400px;
				}
			}
		}
	}
}

.menu-title {
	letter-spacing: .05em;
	cursor: default;
	font-size: 11px;
	text-transform: uppercase;
	color: $vertical-menu-title-color;
	font-weight: $font-weight-semibold;

	span {
		padding: 12px 20px;
		display: inline-block;
	}

	i {
		display: none;
	}
}

.vertical-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba($dark, 0.35);
	z-index: 1003;
	display: none;
}

// Mobile Menu
.vertical-sidebar-enable {
	.vertical-overlay {
		display: block;
	}

	.app-menu {
		margin-left: 0 !important;
		z-index: 1004;
	}
}
