//
// _pagination.scss
//

// Pagination Separated
.pagination-separated {
  .page-item {
    .page-link {
      margin-left: 0.35rem;
      border-radius: $border-radius;
    }
  }
}

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    min-width: 32px;
    min-height: 32px;
    text-align: center;
  }

  &.pagination-sm {
    .page-link {
      min-width: 25px;
      min-height: 25px;
    }
  }
}

.page-item.active {
  .page-link {
    box-shadow: $element-shadow;
  }
}

.paginator {
  display: flex;
  justify-content: space-between;
  padding-block: 1.5rem;
  width: 97%;
  margin-inline: auto;

  .paginator-results {
    > span {
      @include bold-text;
    }
  }
}

.pagination-container {
  display: flex;
  list-style-type: none;
  gap: 4px;

  .pagination-item {
    height: 32px;
    text-align: center;
    color: $purple-medium;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
    border: 1px solid $gray-placeholder;

    &:nth-child(1),
    &:last-child {
      width: 40px;

      svg {
        height: 1.3rem;
        width: 1.3rem;
      }
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: $purple-medium;
      color: $white;
      border: 1px solid $purple-medium;
    }

    &.disabled {
      pointer-events: none;
      color: $gray-400;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
