.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.fit-content {
  width: fit-content;
  padding: 0;
}

/* hide scrollbar but allow scrolling */
.scroll-container {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  border-right: solid 1px $gray-outline;
}

.scroll-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

@mixin normal-text {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}

@mixin bold-text {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
