//
// Google font - Poppins
//

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

//
// Mime Font : BrandonText
//

@font-face {
	font-family: "BrandonText";
	src: url("../../fonts/BrandonText-Regular.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "BrandonText";
	src: url("../../fonts/BrandonText-RegularItalic.otf") format("opentype");
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: "BrandonText";
	src: url("../../fonts/BrandonText-Thin.otf") format("opentype");
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: "BrandonText";
	src: url("../../fonts/BrandonText-Light.otf") format("opentype");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "BrandonText";
	src: url("../../fonts/BrandonText-Bold.otf") format("opentype");
	font-weight: 700;
	font-style: normal;
}