$form-button-height: 28px;

.page-content {
  .product-item {
    border-bottom: solid 1px $gray-outline;
    height: 79px;
  }
  .connection-container,
  .my-audience-container,
  .color-benchmarks-container,
  .undeserved-shades-container {
    padding: 1.1875rem 3.125rem 0 3.125rem;
    display: flex;
    flex-direction: column;
    gap: 19px;

    .header {
      display: flex;
      .active-duration {
        display: flex;
        justify-content: flex-end;
        padding: 0;
      }
    }
  }
  .connection-list {
    height: calc(100vh - #{$header-height});
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(304px, 1fr));
    gap: 20px 37px;

    .connection-card {
      height: 331px;
      padding: 14px 11px 20px 14px;
      border-radius: 8px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
      border: solid 1px #f8f8f8;
      background-color: $white;

      img {
        min-width: 100%;
        height: 212px;
        min-height: 212px;
        object-fit: cover;
        border-radius: 8px;
      }

      .title {
        color: $gray-5d;
        letter-spacing: 0.37px;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        margin: 8px 0;
      }

      .connection-card-footer {
        display: flex;
        justify-content: space-between;
        padding: 0px 11px 0 14px;
        > .col-9 {
          padding: 0;
        }

        .connection-btn {
          width: fit-content;
          height: $form-button-height;
          padding: 0 2rem;
          background: $purple-7b;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0.37px;
          color: white;
          border: none;
          margin: 0 0 5px;
        }

        .duration {
          font-size: 10px;
          color: $gray-34;
          line-height: 14px;
        }

        .icon-container {
          width: 47px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $purple-mime;
          border-radius: 8px;
          color: $white;
          border: none;
          cursor: pointer;

          svg {
            height: 24px;
            width: 24px;
          }

          &.check-mark {
            background: $gray-placeholder;
          }
        }
      }
    }
  }

  .mime-form textarea.personal-text {
    height: 200px;
  }

  .my-audience-container {
    .chart {
      display: flex;
      gap: 1px;
      min-height: 10px;
      max-height: 10px;
      width: 100%;
      margin-top: 2.3125rem;

      > div {
        padding: 0;
        height: 100%;
      }
    }
  }

  .color-benchmarks-container {
    gap: 2rem;
    .chart {
      min-height: 500px;
      max-height: 500px;

      .recharts-responsive-container {
        // background-color: $gray-300;
        border-radius: 8px;
      }
    }
  }

  .my-audience-container,
  .color-benchmarks-container,
  .undeserved-shades-container {
    .header {
      margin-top: 2.125rem;
      margin-bottom: 1rem;
      justify-content: space-between;
      @include normal-text;
    }
  }

  .undeserved-shades-container {
    .chart {
      min-height: 400px;
    }
    .main-header {
      margin: 0;
    }
    .action-button {
      width: 150px;
      .btn-small {
        width: fit-content;
        height: $form-button-height;
        padding: 0 0.9rem;
        display: flex;
        align-items: center;
        gap: 4px;
        background: $purple-7b;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.37px;
        color: $white;
        border: none;
        border-radius: 8px;
      }
    }
  }
}
