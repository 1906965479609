.page-content {
  .campaign-container {
    .sub-title {
      padding: 0;
    }
  }
  .campaign-content {
    padding: 2rem 5.75rem;
    height: calc(100vh - #{$header-height});
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top-container {
      margin-bottom: 3.3rem;
      font-size: 14px;
      @include normal-text;
      text-align: center;
      letter-spacing: 0.4px;
      color: $gray-34;

      .description {
        margin-top: 15px;
      }

      &.create-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }

    .campaign-input {
      display: flex;
      align-items: center;
      gap: 1.25rem;
    }

    .integration-container {
      span {
        color: $purple-94;
      }
      textarea {
        min-height: 68px;
        overflow: hidden;
      }
      .btn-small.with-input {
        margin-top: 20px;
      }
    }

    .logo-image {
      width: 11rem;
      height: 11rem;
    }

    .title {
      margin-top: 35px;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.58px;
    }

    .form-control.btn-small {
      background: $purple-7b;
    }
  }
}
